import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';
import { selectConfig } from '../../store/config/selector/config.selectors';
import { UserBalances, UserProfile } from '../../interfaces/interfaces';
import { getUserState, selectUser, userTags } from '../../store/user/selectors/user.selector';
import { WebSoketsService } from '../webSockets/web-sokets.service';
import { HelpersService } from '../helpers/helpers.service';
import { selectVerification } from '../../store/verification/selectors/verification.selectors';
import { MetadataService } from '../metabase/metadata.service';

interface ChangePassword {
  password: string;
  newPassword: string;
}

interface RecoveryPassword {
  newPassword: string;
  repeatPassword: string;
  code: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  Config$: Observable<any> = this.store.select(selectConfig);
  userState$: Observable<any> = this.store.select(getUserState);

  userProfile$: Observable<any> = this.store.select(selectUser);
  verification$: Observable<any> = this.store.select(selectVerification);
  userTags$: Observable<[]> = this.store.select(userTags);

  unsubus$: Subject<boolean> = new Subject();
  specialUnsubus$: Subject<boolean> = new Subject();

  url: Array<string> = [];
  curentUrl: number = 0;

  apiKey: string = '';
  token: string = '';
  firstCheck: boolean = false;

  userBalance: Array<UserBalances> = [
    {
      amount: 0,
      currency: 'USD',
      type: 'ACTIVE',
    },
  ];

  once: boolean = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    private navigation: NavigationService,
    private webSocket: WebSoketsService,
    private helpers: HelpersService,
    private metadataService: MetadataService
  ) {}

  redirectUserPageIfNotVerified(): Observable<boolean> {
    return new Observable((observer) => {
      this.Config$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res?.isLoaded) {
          const userValidationLevel = res.config.user.ValidationLevel;
          if (userValidationLevel == 'super-secure' || res.config.country === 'kaz') {
            observer.next(true);
          } else {
            this.verification$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
              if (res?.isLoaded) {
                if (res.data) {
                  const isVerified = res?.data.identityVerification == 'VERIFIED';
                  if (!isVerified) {
                    this.navigation.navigateTo('/(aside:account/verification)');
                  }
                  observer.next(isVerified);
                }
              }
            });
          }
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    });
  }

  // getUserBalance(url: string, apiKey: string, token: string) {
  //   if (apiKey == undefined || token == undefined) return;
  //   if (url == undefined && this.curentUrl !== this.url?.length) {
  //     this.tryOtherWs();
  //     return;
  //   }

  //   this.webSocket.connect(`${url}?token=${token}&api=${apiKey}`).subscribe({
  //     next: (res: any) => {
  //       this.updateUserBalances(res.data);
  //     },
  //     error: () => {
  //       this.tryOtherWs();
  //     },
  //     complete: () => {
  //       this.tryOtherWs();
  //     },
  //   });
  // }
  // updateUserBalances(data: any): void {
  //   // this.userBalances.balance = data?.Balance || this.userBalances.balance;
  //   // this.userBalances.bonusBalance = data?.BonusBalance * 1 || this.userBalances.bonusBalance;
  //   // this.store.dispatch(
  //   //   SetUserBalances['[SetUserBalances]SetUserBalanceSuccess']({
  //   //     data: this.userBalances,
  //   //   })
  //   // );
  // }

  // tryOtherWs() {
  //   this.curentUrl++;
  //   if (this.curentUrl > this.url?.length) return;
  //   this.getUserBalance(this.url[this.curentUrl], this.apiKey, this.token);
  // }

  // getUserBalanceFromRequest(): Observable<UserBalances> {
  //   return new Observable((observer) => {
  //     this.userProfile$.pipe(takeUntil(this.specialUnsubus$)).subscribe((res) => {
  //       const userInfo = res?.userInfo?.data;
  //       const userProfile = res?.userProfile?.data;
  //       if (userInfo && userProfile) {
  //         if (!this.once) {
  //           this.userBalances.currency = userProfile.currency?.toUpperCase();
  //           const bonusesBalance = userInfo.loyalty?.BonusesBalance;
  //           if (bonusesBalance && Object.keys(bonusesBalance).length > 0) {
  //             const key = Object.keys(bonusesBalance)[0];
  //             this.userBalances.bonusBalance = bonusesBalance[key]?.Balance * 1;
  //             this.userBalances.balance = userInfo.balance - this.userBalances.bonusBalance;
  //           } else {
  //             this.userBalances.balance = userInfo.balance;
  //           }
  //           this.userBalances.points = userInfo.loyalty?.Balance;
  //           this.store.dispatch(
  //             SetUserBalances['[SetUserBalances]SetUserBalanceSuccess']({
  //               data: this.userBalances,
  //             })
  //           );
  //           this.once = true;
  //         }
  //         this.url = [userInfo.socketsData?.server, userInfo.socketsData?.server2];
  //         this.apiKey = userInfo.socketsData?.api;
  //         this.token = userInfo.socketsData?.token;

  //         this.getUserBalance(this.url[0], this.apiKey, this.token);
  //         observer.next(this.userBalances);
  //       }
  //     });
  //   });
  // }
  getUserBalanceFromRequest() {
    return this.http.get('/api/v2/payments/balance/get');
  }
  getUserInfo() {
    return this.http.get('/userInfo');
  }

  getUserProfile() {
    return this.http.get('/api/v2/user/profile');
  }

  redirectForSpecialParams(link: string, code: string): Observable<boolean> {
    return new Observable((observer) => {
      if (link) {
        this.navigation.navigateTo(link, {
          code: code,
        });
      }
      observer.next(true);
      observer.complete();
    });
  }
  blockRestrictedCountry(): Observable<boolean> {
    return new Observable((observer) => {
      this.Config$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res?.isLoaded) {
          if (res?.config?.status === 451) {
            this.navigation.navigateTo('/');
            observer.next(false);
          } else {
            observer.next(true);
          }
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    });
  }

  pauseUserAccaunt(year: string, month: string, day: string, time: string) {
    this.http.put(`/profiles/disable?lang=en&dateTo=${year}-${month}-${day}%20${time}`, {}).subscribe(() => {
      // this.logout();
    });
  }

  pauseAccountPermanently(loginID: string[], year: string, month: string, day: string, time: string): void {
    this.http
      .post('https://tagsync.coduluz.com/permanent-block', {
        login: loginID[1],
        merchantId: '23848259',
      })
      .subscribe(() => {
        this.pauseUserAccaunt(year, month, day, time);
      });
  }

  // logout() {
  //   localStorage.removeItem('url');
  //   this.http.delete('/auth').subscribe(() => {
  //     window.location.href = '/';
  //   });
  //   this.cookieService.deleteCookie('loyalty_refresh_token');
  //   this.cookieService.deleteCookie('loyalty_access_token');
  // }
  setNewPassword(password: string, newPassword: string) {
    const params: ChangePassword = {
      password: password,
      newPassword: newPassword,
    };
    return this.http.post<any>('/api/v2/user/auth/reset-password', params);
  }
  recoveryPassword(newPassword: string, repeatPassword: string, code: string) {
    const params: RecoveryPassword = {
      newPassword,
      repeatPassword,
      code,
    };

    return this.http.put<any>('/userPassword', params);
  }

  updateUserProfile(profile: UserProfile) {
    return this.http.post(`/api/v2/user/profile/update`, profile);
  }
  setUserLanguage(lang: string, email: string) {
    return this.http.patch(`/profiles/language?lang=${lang}`, {
      email,
    });
  }
}
